<div tabindex="-1" role="dialog" [attr.aria-valuenow]="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <a class="closeModal" [attr.aria-label]="Close" data-bs-dismiss="modal" (click)="closeModal()">
          <img src="assets/images/close.png">
        </a>
        <p class="mb-3" *ngIf="pageType == 'inPage-Editor'">{{'web.inpage_select_upload_option_text' | translate}} </p>
        <p class="mb-3" *ngIf="pageType == 'book-Editor'">{{'web.bookeditor_select_upload_option_text' | translate}}
        </p>
        <div *ngIf="pageType == 'inPage-Editor'">
          <div class="icon p-2">
            <input type="file" multiple #fileInput accept=".jpg,.jpeg" style="display: none;" (change)="onUpload()" />
            <img (click)="uploadPhotos('local')" class="me-5" src="../assets/images/upload_device.png" alt="">
            <img (click)="uploadPhotos('album')" src="../assets/images/my_album_upload.png" alt="">
          </div>
        </div>
      </div>
      <div class="modal-footer" *ngIf="pageType == 'inPage-Editor'">
      </div>
      <div class="modal-footer" *ngIf="pageType == 'book-Editor'">
        <button class="btn btn-primary" type="button" data-bs-dismiss="modal" (click)="gotoBookEditor('quickBook')">Quick
          View</button>
        <button class="btn btn-primary" type="button" data-bs-dismiss="modal" (click)="gotoBookEditor('bookEditor')">Full
          View</button>
      </div>
    </div>
  </div>
</div>
