import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HammerModule } from '@angular/platform-browser';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { ProductSet3V1Component } from './product-set3-v1/product-set3-v1.component';
import { EasyStepsSet3V1Component } from './easy-steps-set3-v1/easy-steps.component';
import { Gallery24ScrollV1Component } from './gallery24-scroll-v1/gallery24-scroll-v1.component';
import { GallerySet4V1Component } from './gallery-set4-v1/gallery-set4-v1.component';
import { AbovefooterAccordionComponent } from './abovefooter-accordion/abovefooter-accordion.component';
import { TextBlocksComponent } from './text-blocks/text-blocks.component';
import { HerocoverL1v1Component } from './herocover-l1v1/herocover-l1v1.component';
import { HerocoverR1v1Component } from './herocover-r1v1/herocover-r1v1.component';
import { BannerGallery1FullComponent } from './banner-gallery1-full/banner-gallery1-full.component';
import { TestimonialSetV1Component } from './testimonial-set-v1/testimonial-set-v1.component';
import { HeroPromoRv1Component } from './hero-promo-rv1/hero-promo-rv1.component';
import { HeroStartCreateLV1Component } from './hero-start-create-lv1/hero-start-create-lv1.component';
import { CtaBannerComponent } from './cta-banner/cta-banner.component';
import { FeatureSet4V1Component } from './feature-set4-v1/feature-set4-v1.component';
import { FeatureSet3V1Component } from './grid-15-v1/grid-15-v1.component';
import { Grid24V1Component } from './grid24-v1/grid24-v1.component';
import { BlockL11V1Component } from './block-l11-v1/block-l11-v1.component';
import { BlockR11V1Component } from './block-r11-v1/block-r11-v1.component';
import { HeroL1V1Component } from './hero-l1-v1/hero-l1-v1.component';
import { HeroR1V1Component } from './hero-r1-v1/hero-r1-v1.component';
import { ProductSet4V1Component } from './product-set4-v1/product-set4-v1.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
// import { NgImageSliderModule } from 'ng-image-slider';
import { ProductSet2V1Component } from './product-set2-v1/product-set2-v1.component';
import { ProdutselectorComponent } from './produtselector/produtselector.component';

import { BlockL9V1Component } from './block-l9-v1/block-l9-v1.component';
import { BlockR9V1Component } from './block-r9-v1/block-r9-v1.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductChangeModalComponent } from './product-change-modal/product-change-modal.component';
import { BlockC20V1Component } from './block-c20-v1/block-c20-v1.component';
import { HeroCoverL4V1Component } from './hero-cover-l4-v1/hero-cover-l4-v1.component';
import { HeroCoverR4V1Component } from './hero-cover-r4-v1/hero-cover-r4-v1.component';
import { HeroL3V1Component } from './hero-l3-v1/hero-l3-v1.component';
import { HeroR4V1Component } from './hero-r4-v1/hero-r4-v1.component';
import { BlockL2V1Component } from './block-l2-v1/block-l2-v1.component';
import { BlockC22V1Component } from './block-c22-v1/block-c22-v1.component';
import { BlockC23V1Component } from './block-c23-v1/block-c23-v1.component';
import { BlockL6V1Component } from './block-l6-v1/block-l6-v1.component';
import { BlockR6V1Component } from './block-r6-v1/block-r6-v1.component';
import { Grid8V1Component } from './grid8-v1/grid8-v1.component';
import { Page404detailComponent } from './page404detail/page404detail.component';
import { HeroR3V1Component } from './hero-r3-v1/hero-r3-v1.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorTypesComponent } from './editor-types/editor-types.component';
import { UploadModule } from '../upload/upload.module';
import { BlockR18V1Component } from './block-r18-v1/block-r18-v1.component';
import { PricelistComponent } from './pricelist/pricelist.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HelpScreenComponent } from './help-screen/help-screen.component';
import { HelpImageTextComponent } from './help-image-text/help-image-text.component';
import { VideoModalComponent } from './video-modal/video-modal.component';
import { EmailverificationmodalComponent } from './emailverificationmodal/emailverificationmodal.component';
import { ResetpasswordmodalComponent } from './resetpasswordmodal/resetpasswordmodal.component';
import { successmodalComponent } from './newslettersuccessmodal/newslettersuccessmodal.component'
import { Grid26V1Component } from './grid26-v1/grid26-v1.component';
import { Grid27V1Component } from './grid27-v1/grid27-v1.component';
import { NewsletterSubscriptionModalComponent } from './newsletter-subscription-modal/newsletter-subscription-modal.component';
import { ThemeselectormodalComponent } from './themeselectormodal/themeselectormodal.component';
import { ThemeSelectorComponent } from './theme-selector/theme-selector.component';
import { ReferalPopupComponent } from './referal-popup/referal-popup.component';
import { PwaconfirmationComponent } from './pwaconfirmation/pwaconfirmation.component';
import { AppupdatemodalComponent } from './appupdatemodal/appupdatemodal.component';
import { FKGrid02Component } from './fk-grid02/fk-grid02.component';
import { FkGrid03Component } from './fk-grid03/fk-grid03.component';
import { FkGrid0301Component } from './fk-grid0301/fk-grid0301.component';
import { FkGrid0302Component } from './fk-grid0302/fk-grid0302.component';
import { FkGrid0303Component } from './fk-grid0303/fk-grid0303.component';
import { FkGrid201Component } from './fk-grid201/fk-grid201.component';
import { FkGrid101LComponent } from './fk-grid101-l/fk-grid101-l.component';
import { FkGrid101RComponent } from './fk-grid101-r/fk-grid101-r.component';
import { FkGrid401Component } from './fk-grid401/fk-grid401.component';
import { FtkHeroV01LComponent } from './ftk-hero-v01-l/ftk-hero-v01-l.component';
import { FtkHeroV01RComponent } from './ftk-hero-v01-r/ftk-hero-v01-r.component';
import { FTKBlock101LComponent } from './ftk-block101-l/ftk-block101-l.component';
import { FTKBlock101RComponent } from './ftk-block101-r/ftk-block101-r.component';
import { FTKBlock103Component } from './ftk-block103/ftk-block103.component';
import { FTKText101Component } from './ftk-text101/ftk-text101.component';
import { FTKBlock104Component } from './ftk-block104/ftk-block104.component';
import { FTKBlockv101LComponent } from './ftkblockv101-l/ftkblockv101-l.component';
import { FTKCarousel301Component } from './ftk-carousel301/ftk-carousel301.component';
import { FTKBlock105Component } from './ftk-block105/ftk-block105.component';
import { FTKBlock102RComponent } from './ftkblock102-r/ftkblock102-r.component';
import { CommonmodalComponent } from './commonmodal/commonmodal.component';
import { ELGrid204Component } from './el-grid204/el-grid204.component';
import { ElGrid205Component } from './el-grid205/el-grid205.component';
import { ElBlock001Component } from './el-block001/el-block001.component';
import { ELGrid304Component } from './el-grid304/el-grid304.component';
import { ELGrid102RComponent } from './el-grid102-r/el-grid102-r.component';
import { ELGrid102LComponent } from './el-grid102-l/el-grid102-l.component';
import { OMQComponent } from './omq/omq.component';
import { OMQFORMComponent } from './omqform/omqform.component';
import { FTKBlock106Component } from './ftkblock106/ftkblock106.component';
import { ELBlock107Component } from './el-block107/el-block107.component';
import { ELBlock002Component } from './el-block002/el-block002.component';
import { ELHero01CComponent } from './el-hero01-c/el-hero01-c.component';
import { ELGrid206Component } from './el-grid206/el-grid206.component';
import { ELText102Component } from './el-text102/el-text102.component';
import { UploadProgressComponent } from './upload-progress/upload-progress.component';
import { ElSlider1Component } from './el-slider1/el-slider1.component';
import { GameComponent } from './game/game.component';
import { ELBlock003Component } from './el-block003/el-block003.component';
//import { MpbHeaderv2Component } from './mpb-headerv2/mpb-headerv2.component';
// import { MPBHeaderv1Component } from './mpb-headerv1/mpb-headerv1.component';
// import { ElHeaderV2Component } from './EL-Header-2/EL-Header-2.component';
// import { FTKCarousel301Component } from './ftk-carousel301/ftk-carousel301.component';
// import { ELFooterV1Component } from './EL-Footer-1/EL-Footer-1.component';
// import { ElHeaderV1Component } from './EL-Header-1/EL-Header-1.component';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ElHero03Component } from './el-hero03/el-hero03.component';
import { ElGrid307Component } from './el-grid307/el-grid307.component';
import { ELHero03RComponent } from './el-hero03-r/el-hero03-r.component';
import { ELHero03LComponent } from './el-hero03-l/el-hero03-l.component';
import { ELHero02LComponent } from './el-hero02-l/el-hero02-l.component';
import { ELHero02RComponent } from './el-hero02-r/el-hero02-r.component';
import { ELHero02CComponent } from './el-hero02-c/el-hero02-c.component';
import { ELGrid305Component } from './el-grid305/el-grid305.component';
import { ELGrid306Component } from './el-grid306/el-grid306.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CreateQrCodeComponent } from './create-qr-code/create-qr-code.component';
// import { QRCodeModule } from 'angularx-qrcode';
import { SvgIconModule } from 'src/lib/svg-icon.module';
// import { QRCodeModule } from 'angularx-qrcode';
import { QRCodeComponent } from 'angularx-qrcode';
import { DynamicPopupsModalComponent } from '../account/dynamic-popups-modal/dynamic-popups-modal.component';

@NgModule({
  declarations: [
    ProductSet3V1Component,
    EasyStepsSet3V1Component,
    Gallery24ScrollV1Component,
    GallerySet4V1Component,
    AbovefooterAccordionComponent,
    TextBlocksComponent,
    HerocoverL1v1Component,
    HerocoverR1v1Component,
    BannerGallery1FullComponent,
    TestimonialSetV1Component,
    HeroPromoRv1Component,
    HeroStartCreateLV1Component,
    CtaBannerComponent,
    FeatureSet4V1Component,
    FeatureSet3V1Component,
    Grid24V1Component,
    BlockL11V1Component,
    HeroL1V1Component,
    HeroR1V1Component,
    BlockR11V1Component,
    ProductSet4V1Component,
    ProductSet2V1Component,
    ProdutselectorComponent,
    BlockL9V1Component,
    BlockR9V1Component,
    ProductChangeModalComponent,
    BlockC20V1Component,
    HeroCoverL4V1Component,
    HeroCoverR4V1Component,
    HeroL3V1Component,
    HeroR4V1Component,
    BlockL2V1Component,
    BlockC22V1Component,
    BlockC23V1Component,
    BlockL6V1Component,
    BlockR6V1Component,
    Grid8V1Component,
    Page404detailComponent,
    HeroR3V1Component,
    EditorTypesComponent,
    BlockR18V1Component,
    PricelistComponent,
    ContactUsComponent,
    HelpScreenComponent,
    HelpImageTextComponent,
    VideoModalComponent,
    EmailverificationmodalComponent,
    CommonmodalComponent,
    ResetpasswordmodalComponent,
    successmodalComponent,
    Grid26V1Component,
    Grid27V1Component,
    NewsletterSubscriptionModalComponent,
    ThemeselectormodalComponent,
    ThemeSelectorComponent,
    ReferalPopupComponent,
    PwaconfirmationComponent,
    AppupdatemodalComponent,
    FKGrid02Component,
    FkGrid03Component,
    FkGrid0301Component,
    FkGrid0302Component,
    FkGrid0303Component,
    FkGrid201Component,
    FkGrid101LComponent,
    FkGrid101RComponent,
    FkGrid401Component,
    FtkHeroV01LComponent,
    FtkHeroV01RComponent,
    FTKBlock101LComponent,
    FTKBlock101RComponent,
    FTKBlock103Component,
    FTKText101Component,
    FTKBlock104Component,
    FTKBlockv101LComponent,
    FTKCarousel301Component,
    FTKBlock105Component,
    FTKBlock102RComponent,
    ELGrid204Component,
    ElGrid205Component,
    ElBlock001Component,
    ELGrid304Component,
    ELGrid102RComponent,
    ELGrid102LComponent,
    OMQComponent,
    OMQFORMComponent,
    FTKBlock106Component,
    ELBlock107Component,
    ELBlock002Component,
    ELHero01CComponent,
    ELGrid206Component,
    ELText102Component,
    UploadProgressComponent,
    ElSlider1Component,
    GameComponent,
    ELBlock003Component,
    ElHero03Component,
    ElGrid307Component,
    ELHero03RComponent,
    ELHero03LComponent,
    ELHero02LComponent,
    ELHero02RComponent,
    ELHero02CComponent,
    ELGrid305Component,
    ELGrid306Component,
    CreateQrCodeComponent,
    DynamicPopupsModalComponent
    //MpbHeaderv2Component,
    // MPBHeaderv1Component,
    // ElHeaderV2Component,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HammerModule,
    // NgImageSliderModule,
    SlickCarouselModule,
    UploadModule,
    LazyLoadImageModule,
    SvgIconModule,
    NgxCaptchaModule,
    // QRCodeModule
  ],
  exports: [
    PricelistComponent,CreateQrCodeComponent
  ]

})
export class SharedModule { }
