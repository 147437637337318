import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UploadbarComponent } from './uploadbar/uploadbar.component';
import { PreviewComponent } from './preview/preview.component';

import { DragDropModule } from '@angular/cdk/drag-drop';



@NgModule({
  declarations: [UploadbarComponent, PreviewComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    DragDropModule
  ],
  exports: [PreviewComponent]
})
export class UploadModule { }
