import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as uuid from 'uuid';
import { GlobalService } from 'src/app/services/global.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
@Component({
    selector: 'app-editor-types',
    templateUrl: './editor-types.component.html',
    styleUrls: ['./editor-types.component.scss'],
    standalone: false
})
export class EditorTypesComponent implements OnInit {
pageType:any;
Close:any;
projectId:any;
exampleModalLabel:any;
data:any;

  constructor(private modalService: NgbModal,private globalService: GlobalService,private route: Router) { }

  ngOnInit(): void {
  this.pageType=this.data.pageType;
  }

   gotoBookEditor(page) {
       this.closeModal();
        let projectId;
        if(this.projectId != null && this.projectId != 'null'){
             projectId = this.projectId;

        }
        else{

            projectId = uuid.v4();

       }
       let currentProductInfo={
          productCode:this.data.variableInfo2,
          preview:this.data.variableInfo,
          categoryCode:this.data.variableInfo,
          paperCode:this.data.variableInfo4,
          theme:'',
          projectId
       };
       let  data = { 'product':currentProductInfo, 'page': page, 'projectId': projectId };
          if (page == 'quickBook') {

            if (localStorage.getItem('userInfo') == null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.globalService.setUserData(userInfo);
                this.globalService.setCartLengthData(0);
                let data = { 'product':currentProductInfo, 'page': page, 'projectId': projectId };
                this.globalService.loginWithGuestUser(data);
            }
            else {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.globalService.googleTrackingCodeCreateProject(currentProductInfo.productCode);
                this.globalService.setUserData(userInfo);
                if(currentProductInfo.paperCode){
                  this.route.navigate(['create/easy/'+currentProductInfo.productCode+'/'+projectId+'/'+currentProductInfo.paperCode])
                }else{
                  this.route.navigate(['create/easy/'+currentProductInfo.productCode+'/'+projectId])
                }
               

            }
        }
        else if (page == 'bookEditor') {
           this.globalService.createNow(currentProductInfo);
        }

   }

closeModal(){
       this.modalService.dismissAll();
    }
}

